<template>
  <div>
    <load-profile></load-profile>
    <n-search-container
      @search="search"
      @reset="reset"
      @excel="exportExcel"
      :fields="searchFields"
      :loading="loading"
      :show-export-excel="true"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
        <template #departmentId="item">
          <b-form-group :label-for="item.field.key">
            <label>
              {{ $t(item.field.label) }}
            </label>
            <n-async-single-select
              v-model="params[item.field.key]"
              :query="{
                limit: 0,
                company_id: params.companyId,
              }"
              :ref="item.field.key"
              :name="item.field.key"
              :repository="item.field.repository"
              :selection-key="item.field.selectionKey"
              :selection-label="item.field.selectionLabel"
            ></n-async-single-select>
          </b-form-group>
        </template>
      </n-search-input>
    </n-search-container>

    <b-card no-body class="mb-0">
      <div class="m-1">
        <b-row>
          <b-col>
            <label>{{ $t("field.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <b-col class="text-right" v-if="$can('create', resource)">
            <b-button
              class="mr-1"
              variant="outline-primary"
              :to="{ name: `chart-${resource}` }"
              >{{ $t("button.organizationalChart") }}</b-button
            >
            <b-button variant="primary" :to="{ name: `create-${route}` }">{{
              $t("button.create")
            }}</b-button>
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          ref="table"
          @sort-changed="sortChanged"
          @destroy="destroy"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
          :deletable="true"
          :resource="resource"
          :route="route"
        >
          <template #cell(company)="data">
            <span v-if="data.item.company">
              {{ data.item.company.name }}
            </span>
          </template>
          <template #cell(department)="data">
            <span v-if="data.item.department">
              {{ data.item.department.name }}
            </span>
          </template>
          <template #cell(manager)="data">
            <span v-if="data.item.manager">
              {{ data.item.manager.name }}
            </span>
          </template>
          <template #cell(position)="data">
            <span v-if="data.item.position">
              {{ data.item.position.name }}
            </span>
          </template>
          <template #cell(status)="data">
            <b-badge
              pill
              variant="success"
              badge-glow
              v-if="data.item.isEnable"
            >
              {{ $t(`field.active`) }}
            </b-badge>
            <b-badge pill variant="danger" badge-glow v-else>
              {{ $t(`field.inactive`) }}
            </b-badge>
          </template>

          <template #cell(canReview)="data">
            <b-badge
              pill
              variant="success"
              badge-glow
              v-if="data.item.canReview"
            >
              {{ $t(`status.can`) }}
            </b-badge>
            <b-badge pill variant="danger" badge-glow v-else>
              {{ $t(`status.cannot`) }}
            </b-badge>
          </template>
          <template #cell(canReviewFinal)="data">
            <b-badge
              pill
              variant="success"
              badge-glow
              v-if="data.item.canReviewFinal"
            >
              {{ $t(`status.can`) }}
            </b-badge>
            <b-badge pill variant="danger" badge-glow v-else>
              {{ $t(`status.cannot`) }}
            </b-badge>
          </template>
          <template #cell(canApprove)="data">
            <b-badge
              pill
              variant="success"
              badge-glow
              v-if="data.item.canApprove"
            >
              {{ $t(`status.can`) }}
            </b-badge>
            <b-badge pill variant="danger" badge-glow v-else>
              {{ $t(`status.cannot`) }}
            </b-badge>
          </template>
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <b-button
                v-if="$can('update', resource)"
                :to="{
                  name: `update-${route}`,
                  params: { id: data.item.id },
                }"
                v-b-tooltip.hover
                :title="$t('button.update')"
                variant="primary"
                pill
                size="sm"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <!-- <b-button
                v-if="$can('delete', resource)"
                v-b-tooltip.hover
                :title="$t('button.delete')"
                variant="danger"
                pill
                size="sm"
                @click="
                  $refs.table.destroy(data.item.id, data.index, data.item)
                "
              >
                <feather-icon icon="TrashIcon" />
              </b-button> -->
            </div>
          </template>
        </n-table>
        <n-pagination
          ref="pagination"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
        ></n-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BAvatar,
  BBadge,
  BFormGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import SearchInputs from "./searchInput";
import { avatarText } from "@core/utils/filter";
import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";
import moment from "moment";

const EmployeeRepository = Repository.get("employee");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BBadge,
    BFormGroup,

    NPagination,
    NSearchContainer,
    NSearchInput,
    NTable,
    vSelect,
    NAsyncSingleSelect,
  },
  watch: {
    perPage() {
      this.list();
    },
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  data() {
    return {
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        companyId: Number(this.$route.query.companyId) || null,
        departmentId: Number(this.$route.query.departmentId) || null,
        managerId: Number(this.$route.query.managerId) || null,
        positionId: Number(this.$route.query.positionId) || null,
        idCard: this.$route.query.idCard || null,
        name: this.$route.query.name || null,
        canReview: this.$route.query.canReview || null,
        canReviewFinal: this.$route.query.canReviewFinal || null,
        canApprove: this.$route.query.canApprove || null,
        isEnable: this.$route.query.isEnable || null,
      },
      query: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        name: this.$route.query.name || null,
        isEnable: this.$route.query.isEnable || null,
      },
      a: null,
      avatarText,
    };
  },
  watch: {
    "params.companyId"(newValue, oldValue) {
      this.$refs.departmentId.clear();
    },
    perPage() {
      this.list();
    },
  },
  mounted() {
    this.a = document.createElement("a");
    document.body.appendChild(this.a);
    this.a.style = "display: none";

    this.getData();
  },
  methods: {
    updateQuerySting() {
      this.$router.replace({ query: this.query });
    },
    list(page = 1) {
      this.query.page = page;
      this.updateQuerySting();
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.query.order = sortBy;
      this.query.sort = sortDirection;
      this.updateQuerySting();
      this.getData();
    },
    search() {
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.query.page = 1;
      this.$refs.pagination.reset();
      this.updateQuerySting();
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.query.page = 1;
      this.$refs.pagination.reset();
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.updateQuerySting();
      this.getData();
    },
    getData() {
      this.loading = true;
      const params = {
        ...this.query,
        searchFields: this.searchFields,
      };
      EmployeeRepository.index(params)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    destroy(id) {
      EmployeeRepository.destroy(id)
        .then((response) => {
          this.getData();
        })
        .catch((error) => {});
    },
    exportExcel() {
      this.loading = true;

      EmployeeRepository.exportExcel()
        .then((response) => {
          this.loading = false;
          let blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let url = window.URL.createObjectURL(blob);
          this.a.href = url;
          this.a.download = `Employee ${moment().format("DD-MMM-YYYY")}.xlsx`;
          this.a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = TableFields;
    const searchFields = SearchInputs;
    const resource = "employee";
    const route = "employee";

    return {
      fields,
      searchFields,
      resource,
      route,
    };
  },
};
</script>
