export default [
  {
    key: "companyId",
    label: "field.company",
    type: "nAsynSingleSelection",
    repository: "company",
    selectionKey: "id",
    selectionLabel: "name",
    clearable: true,
    query: { limit: 0 },
  },
  {
    key: "departmentId",
    label: "field.department",
    type: "nAsynSingleSelection",
    repository: "department",
    selectionKey: "id",
    selectionLabel: "name",
    clearable: true,
    query: { limit: 0 },
  },
  {
    key: "managerId",
    label: "field.supervisor",
    type: "nAsynSingleSelection",
    repository: "employee",
    selectionKey: "id",
    selectionLabel: "name",
    clearable: true,
    query: { limit: 0 },
  },
  {
    key: "positionId",
    label: "field.position",
    type: "nAsynSingleSelection",
    repository: "position",
    selectionKey: "id",
    selectionLabel: "name",
    clearable: true,
    query: { limit: 0 },
  },
  {
    key: "idCard",
    label: "field.idCard",
    type: "text",
  },
  {
    key: "name",
    label: "field.name",
    type: "text",
  },
  {
    key: "canReview",
    label: "field.canReview",
    rules: "required",
    type: "radio",
    cast: "boolean",
    options: [
      { text: "field.can", value: "1" },
      { text: "field.cannot", value: "0" },
    ],
    lg: "auto",
  },
  {
    key: "canReviewFinal",
    label: "field.canReviewFinal",
    rules: "required",
    type: "radio",
    cast: "boolean",
    options: [
      { text: "field.can", value: "1" },
      { text: "field.cannot", value: "0" },
    ],
    lg: "auto",
  },
  {
    key: "canApprove",
    label: "field.canApprove",
    rules: "required",
    type: "radio",
    cast: "boolean",
    options: [
      { text: "field.can", value: "1" },
      { text: "field.cannot", value: "0" },
    ],
    lg: "auto",
  },
  {
    key: "isEnable",
    label: "field.status",
    rules: "required",
    type: "radio",
    cast: "boolean",
    options: [
      { text: "field.active", value: "1" },
      { text: "field.inactive", value: "0" },
    ],
    lg: "auto",
  },
];
