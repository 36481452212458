export default [
  {
    key: "increasement",
    sortable: true,
    sortField: "id",
    label: "#",
    thClass: "w-50px",
  },
  {
    key: 'profile',
    label: 'field.profile',
    thClass: 'text-left',
    tdClass: 'text-left',
    type: 'profile',
  },
  {
    key: 'name',
    sortable: true,
    sortField: 'name',
    label: 'field.name',
    stickyColumn: true,
    variant: 'light',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'email',
    label: 'field.email',
    thClass: 'text-left',
  },
  {
    key: 'idCard',
    label: 'field.idCard',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'company',
    label: 'field.company',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'department',
    label: 'field.department',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'position',
    label: 'field.position',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'manager',
    label: 'field.supervisor',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'username',
    label: 'field.username',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'canReview',
    label: 'field.canReview',
  },
  {
    key: 'canReviewFinal',
    label: 'field.canReviewFinal',
  },
  {
    key: 'canApprove',
    label: 'field.canApprove',
  },
  {
    key: 'ipaddress',
    sortable: false,
    sortField: '',
    label: 'field.ipAddress',
  },
  {
    key: 'lastActivityAt',
    sortable: false,
    sortField: '',
    label: 'field.lastActiveDate',
  },
  {
    key: 'status',
    label: 'field.status',
  },
  {
    key: 'createdAt',
    sortable: true,
    sortField: 'createdAt',
    label: 'field.createdDate',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'createdBy',
    label: 'field.createdBy',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'updatedAt',
    sortable: true,
    sortField: 'updatedAt',
    label: 'field.updatedDate',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'updatedBy',
    label: 'field.updatedBy',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'actions',
    label: 'field.action',
  },
];
